import { useEffect, useState } from 'react';

export default function FetchData(url)
{
    const [data, setData] = useState([]);
    useEffect(() => {
        async function loadData() {
            const response = await fetch(url)
            if (!response.ok) {
                return;
            }

            const data = await response.json();
            setData(data);
        }

        loadData();
    }, [url]);
    return data;
}
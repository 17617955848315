import React, { useState } from 'react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import SwitchDarkLight from './switchDarkLight';
import '../css/navbar.css'

export default function Navbar() {
    const [isNavExpanded, setIsNavExpanded] = useState(false)

    return (
        <nav className="navbar dark:bg-[#7a4491]">
            <div className="navbar__dark-mode dark:bg-[#1987c0]">
                <SwitchDarkLight />
            </div>

            <div className="navbar__logo">
                <img src={require("../assets/SAS-Logo-web.png")} alt="Symmetry Logo" />
            </div>
            <button className="navbar__burger dark:bg-[#1987c0]" onClick={() => {setIsNavExpanded(!isNavExpanded)}}>
                {isNavExpanded ? <XMarkIcon className="navbar__burger-icon" aria-hidden="true" /> : <Bars3Icon className="navbar__burger-icon" aria-hidden="true" />}
            </button>

            <div className={ isNavExpanded ? "navbar__menu expanded" : "navbar__menu"}>
                <ul className="dark:bg-[#7a4491]">
                    <li className="navbar__menu-item">
                        <a href="/team" className="dark:hover:bg-[#1987c0]">Team</a>
                    </li>
                    <li className="navbar__menu-item">
                        <a href="/services" className="dark:hover:bg-[#1987c0]">Services</a>
                    </li>
                    <li>
                        {isNavExpanded ? <a href="/" className="dark:hover:bg-[#1987c0]">Home</a> : 
                            <a href="/" className="dark:hover:bg-[#1987c0]">
                                <img src={require("../assets/Logo_Symbol_Only_transparent.png")} alt="Symmetry Rocks" title="Home" className="navbar__rocks-logo" />
                            </a>}
                    </li>
                    <li className="navbar__menu-item">
                        <a href="/blog" className="dark:hover:bg-[#1987c0]">Blog</a>
                    </li>
                    <li className="navbar__menu-item">
                        <a href="/contact" className="dark:hover:bg-[#1987c0]">Contact</a>
                    </li>
                </ul>
            </div>
        </nav>
    )
}
import * as React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Box, Modal, Button } from '@mui/material';
import { useSpring, animated } from '@react-spring/web';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PurifyHTML from '../components/purifyHTML';
import "../css/fadeModal.css"

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '35%',
  width: '50%',
  bgcolor: 'background.paper',
  borderRadius: '2rem',
  borderWidth: '0.55rem',
  borderColor: 'white',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '4px'
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '8px',
    background: '#686868',
  },
  '@media (max-width: 1024px)': {
    width: '90%',
  },
  '@media (max-width: 500px)': {
    height: '70%',
    borderWidth: '0',
  },
};

const theme = createTheme({
  palette: {
    secondary: {
      main: '#7a4491',
    },
    info: {
      main: '#1987c0',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export default function FadeModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Button variant="contained" style={{maxWidth: '250px', maxHeight: '250px', fontSize: '1.3rem' }} color={props.color} onClick={handleOpen}>{props.button}</Button>
      </ThemeProvider>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="fade-modal__name">{props.name}</div>
            <hr className="fade-modal__underline sm:mx-auto" />
            <div className="fade-modal__description" dangerouslySetInnerHTML={{ __html: PurifyHTML(props.description)}} />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
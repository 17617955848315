import React, { useState } from 'react';
import UseDarkMode from './useDarkMode';
import { DarkModeSwitch } from 'react-toggle-dark-mode';

export default function SwitchDarkLight() {
  const [colorTheme, setTheme] = UseDarkMode();
  const [darkMode, setDarkMode] = useState(colorTheme === 'light' ? true : false);

  const toggleDarkMode = checked => {
    setTheme(colorTheme);
    setDarkMode(checked);
  };

  return (
    <>
      <div>
        <DarkModeSwitch sunColor='white' checked={darkMode} onChange={toggleDarkMode} className="navbar__dark-mode-icon" />
      </div>
    </>
  );
}
import React from 'react';
import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Navbar from './components/navbar';
import Home from "./pages/home";
import Team from "./pages/team";
import Services from "./pages/services";
import Blog from "./pages/blog";
import Contact from "./pages/contact";
import "./css/shared.css"

function ScrollToTop()
{
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function App() {
  return (
    <>
      <Router>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="*" exact element={<Home />} />
          <Route path="/team" element={<Team />} />
          <Route path="/services" element={<Services />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
      <footer className="main-footer dark:bg-[#7a4491]">
        <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
          <div className="flex flex-col lg:flex-row justify-between items-center">
            <div className="mb-6 md:mb-0">
              <img src={require("./assets/SAS-Logo-web.png")} className="main-footer__picture" id="sym-logo" alt="Symmetry Logo" />
            </div>
            <div className="mb-6 md:mb-0">
              <p className="mb-2">
                70 Birch Alley, Suite 240<br />
                PMB 24006<br />
                Beavercreek, OH 45440
              </p>
              <a href="tel:9377017961" className="dark:hover:text-[#1987c0]">(937) 701-7961</a>
              <div className="mt-2">
                <a href="mailto:solutions@symsolutionscpa.com" className="dark:hover:text-[#1987c0]">solutions@symsolutionscpa.com</a>
              </div>
            </div>
            <div className="mb-6 md:mb-0">
              <img src={require("./assets/Logos website collage.jpg")} className="main-footer__picture" id="collage" alt="Website collage" />
            </div>
          </div>
          <hr className="my-6 sm:mx-auto lg:my-8" />
          <div className="flex flex-col lg:flex-row justify-between items-center">
            <span className="text-sm sm:text-center"><b>Website Developer:</b> <a href="https://www.linkedin.com/in/danielle-kossler-168523259/" className="dark:hover:text-[#1987c0]" target="_blank" rel="noreferrer noopener">Danielle Kossler</a></span>
            <span className="text-sm sm:text-center">&#169; 2024 Symmetry Advisory Solutions | All Rights Reserved</span>
            <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
              <a href="https://www.facebook.com/SymmetryAdvisorySolutions/" target="_blank" rel="noreferrer noopener">
                <svg className="dark:hover:fill-[#1987c0]" id="facebook" fillRule="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" /></svg>
                <span className="sr-only">Facebook page</span>
              </a>
              <a href="https://www.instagram.com/symsolutions/" target="_blank" rel="noreferrer noopener">
                <svg className="dark:hover:fill-[#1987c0]" id="instagram" fillRule="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd" /></svg>
                <span className="sr-only">Instagram page</span>
              </a>
              <a href="https://twitter.com/symsolutionscpa" target="_blank" rel="noreferrer noopener">
                <svg className="dark:hover:fill-[#1987c0]" id="twitter" fillRule="currentColor" viewBox="0 -8 106 106" aria-hidden="true"><path d="M 72.349 0 H 17.651 C 7.902 0 0 7.902 0 17.651 v 54.699 C 0 82.098 7.902 90 17.651 90 h 54.699 C 82.098 90 90 82.098 90 72.349 V 17.651 C 90 7.902 82.098 0 72.349 0 z M 56.032 70.504 L 41.054 50.477 L 22.516 70.504 h -4.765 L 38.925 47.63 L 17.884 19.496 h 16.217 L 47.895 37.94 l 17.072 -18.444 h 4.765 L 50.024 40.788 l 22.225 29.716 H 56.032 z" clipRule="evenodd" /></svg>
                <span className="sr-only">X (Twitter) page</span>
              </a>
              <a href="https://www.linkedin.com/company/symmetry-advisory-solutions/" target="_blank" rel="noreferrer noopener">
                <svg className="dark:hover:fill-[#1987c0]" id="linkedin" fillRule="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" clipRule="evenodd" /></svg>
                <span className="sr-only">Linkedin page</span>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
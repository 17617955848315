import React, { useState } from 'react';
import { CardContent, CardActionArea } from '@mui/material';
import { Modal, Backdrop, Box, Pagination, Stack } from '@mui/material';
import { useSpring, animated } from '@react-spring/web';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FetchData from '../components/fetchData';
import PurifyHTML from '../components/purifyHTML';
import PropTypes from 'prop-types';
import '../css/blogPosts.css';

const Fade = React.forwardRef(function Fade(props, ref) {
    const {
        children,
        in: open,
        onClick,
        onEnter,
        onExited,
        ownerState,
        ...other
    } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter(null, true);
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited(null, true);
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {React.cloneElement(children, { onClick })}
        </animated.div>
    );
});

Fade.propTypes = {
    children: PropTypes.element.isRequired,
    in: PropTypes.bool,
    onClick: PropTypes.any,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
    ownerState: PropTypes.any,
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    bgcolor: 'background.paper',
    borderRadius: '2rem',
    borderColor: 'white',
    borderWidth: '0.55rem',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
        width: '4px'
    },
    '&::-webkit-scrollbar-thumb': {
        borderRadius: '8px',
        background: '#686868',
    },
};

const theme = createTheme({
    palette: {
        secondary: {
            main: '#7a4491',
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
});

export default function Posts() {
    const [open, setOpen] = React.useState(false);
    const [selectedPost, setSelectedPost] = useState(null);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [page, setPage] = React.useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };

    const posts = FetchData('https://wp.symsolutionscpa.com/wp-json/wp/v2/posts?per_page=100&_embed');

    return (
        <>
            <div className="flex flex-col sm:mx-20">
                <div className="flex flex-row flex-wrap">
                {posts.slice((page - 1) * 9, page * 9).map((post) => (
                    <div className="blog-post__container" key={post.id}>
                        <CardActionArea onClick={() => {
                            setSelectedPost(post);
                            handleOpen();
                        }}>
                            <CardContent>
                                <div className="blog-post__title" dangerouslySetInnerHTML={{ __html: PurifyHTML(post.title.rendered) }} />
                                <div className="blog-post__image-container">
                                    {post._embedded['wp:featuredmedia'] ?
                                        <img src={post._embedded['wp:featuredmedia'][0].source_url} alt={post.title.rendered} className="blog-post__image" />
                                        : null
                                    }
                                </div>
                                <div className="blog-post__date">{new Date(post.date).toLocaleDateString()}</div>
                            </CardContent>
                        </CardActionArea>
                    </div>
                ))}
                </div>
            </div>
            <div className="flex justify-center my-4">
                <Stack spacing={2}>
                    <ThemeProvider theme={theme}>
                        <Pagination count={Math.ceil(posts.length / 9)} page={page} onChange={handleChange} color="secondary" size="large" className="bg-white dark:bg-[#686868]" />
                    </ThemeProvider>
                </Stack>
            </div>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        TransitionComponent: Fade,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <div className="blog-post-open__title" dangerouslySetInnerHTML={{ __html: PurifyHTML(selectedPost?.title.rendered) }} />
                        <div id="transition-modal-description" className="blog-post__content" dangerouslySetInnerHTML={{ __html: PurifyHTML(selectedPost?.content.rendered) }} />
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}
import TeamCards from '../components/teamCards';

export default function Team() {
    return (
        <main className="dark:bg-zinc-800">
            <section className="section" id="team-page">
                <div className="section-padding">
                    <h1 className="section-title dark:text-white">Meet Our Team</h1>
                    <hr className="section-title__underline sm:mx-auto dark:border-white" />
                    <p className="col-text dark:text-white">We understand that choosing an online accounting or virtual CFO firm can be stressful. Learn about the history of Symmetry Advisory Solutions. Our experienced team and innovative cloud-based accounting platform put our clients' minds at ease.</p>
                    <p className="col-text dark:text-white">Symmetry Advisory Solutions was founded at the crossroads of two of the 21st Century’s dominant trends: the push for leaner and more versatile companies, and the power of technology to deliver increasingly specialized services faster and at a lower price point. Our cloud accounting platform makes it possible for firms to outsource every function of a CPA, CFO and financial advisor, while still maintaining close relationships and benefiting from on-demand access to information.</p>
                </div>
            </section>
            <section className="section" id="team-cards-section">
                <div className="section-padding">
                    <TeamCards />
                </div>
            </section>
        </main>
    );
}
import React from "react";
import FetchData from '../components/fetchData';
import PurifyHTML from '../components/purifyHTML';
import Slider from "react-slick";

export default function Testimonials() {
    const testimonials = FetchData('https://wp.symsolutionscpa.com/wp-json/wp/v2/testimonials?_embed');

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 10000,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true,
        arrows: false,
        customPaging: function(i) {
            return (
                <div className="testimonial__dots">
                    <div className="testimonial__dot dark:bg-white dark:text-white">{i+1}</div>
                </div>
            );
        },
      };

    return (
        <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
                <div key={index}>
                    <div className="testimonials__container mb-2">
                        <img className="testimonials__profile-picture" src={testimonial._embedded['wp:featuredmedia'][0].source_url} alt={testimonial._embedded['wp:featuredmedia'][0].alt_text} />
                        <div className="flex flex-col items-center">
                            <div className="testimonials__client-name-type flex flex-row gap-2">
                                <span>{testimonial.title.rendered}</span>
                            </div>
                            <div className="testimonials__client-details flex flex-row gap-2">
                                <span>{testimonial.acf.client_business_position}</span>
                            </div>
                        </div>
                        <div className="testimonials__quote-container dark:bg-[#c0bfc0] dark:border-[#c0bfc0]">
                            <div className="testimonials__client-quote" dangerouslySetInnerHTML={{ __html: PurifyHTML(testimonial.acf.client_quote) }} />
                        </div>
                    </div>
                </div>
            ))}
        </Slider>
    );
}
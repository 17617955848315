import React, { useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import emailjs from '@emailjs/browser';
import validator from 'validator';
import { Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "../css/contact.css"

const theme = createTheme({
    palette: {
        secondary: {
            main: '#7a4491',
        },
        info: {
            main: '#1987c0',
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
});

export default function Contact() {
    const navigateToUrl = () => {
        window.open('https://outlook.office365.com/owa/calendar/SymmetryAdvisorySolutionsLLC1@symsolutionscpa.com/bookings/', '_blank');
    }

    const [emailError, setEmailError] = useState('');

    const validateEmail = (e) => {
        var email = e.target.value;

        if (validator.isEmail(email)) {
            setEmailError('');
        }
        else {
            setEmailError('Please be sure to enter a valid email address.');
        }
    };

    const surveyForm = useRef();

    const sendValidForm = (e) => {
        e.preventDefault();

        if (emailError === '') {
            emailjs.sendForm('outlook_symsolutions', 'client_satisfaction', surveyForm.current, 'cI2r13w9Xi2Fmb8UY')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });

            surveyForm.current.reset();

            alert("Thank you for your submission!");
        }
    };

    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    return (
        <main className="dark:bg-zinc-800">
            <section className="section" id="lets-talk">
                <div className="section-padding lg:px-8">
                    <h1 className="section-title sm:text-4x1 dark:text-white">Let's Talk</h1>
                    <hr className="section-title__underline sm:mx-auto dark:border-white" />
                    <div className="section-grid">
                        <div className="section-grid__column">
                            <h2 className="col-title dark:text-white">Client Satisfaction Survey</h2>
                            <hr className="col-title__underline sm:mx-auto" id="lets-talk__col-title__underline" />
                            <p className="col-text dark:text-white">We greatly appreciate your business and wish to continue developing and deepening our business relationship. Your feedback will be used for internal management purposes to help us identify areas for improvement. Thank you for taking time to provide your feedback.</p>
                            <form ref={surveyForm} onSubmit={sendValidForm} className="space-y-8 mt-5" id="client-satisfaction-survey">
                                <div>
                                    <label htmlFor="user_name" className="form__label dark:text-white">Name:</label>
                                    <input type="text" name="user_name" id="user_name" className="form__input dark:bg-[#c0bfc0]" required />
                                </div>
                                <div>
                                    <label htmlFor="user_email" className="form__label dark:text-white">Email:</label>
                                    <input type="email" name="user_email" id="user_email" onChange={(e) => validateEmail(e)} className="form__input dark:bg-[#c0bfc0]" required />
                                    <span className="form__error">{emailError}</span>
                                </div>
                                <div>
                                    <p className="form__label dark:text-white">Subject:</p>
                                    <ul className="form__checkbox-list">
                                        <li className="form__checkbox-item">
                                            <input type="checkbox" name="subject" id="subject" className="form__checkbox" value="Virtual Accounting" />
                                            <label htmlFor="subject" className="dark:text-white">Virtual Accounting</label>
                                        </li>
                                        <li className="form__checkbox-item">
                                            <input type="checkbox" name="subject2" id="subject" className="form__checkbox" value="Virtual CFO" />
                                            <label htmlFor="subject" className="dark:text-white">Virtual CFO</label>
                                        </li>
                                        <li className="form__checkbox-item">
                                            <input type="checkbox" name="subject" id="subject" className="form__checkbox" value="Company Tax Advisory" />
                                            <label htmlFor="subject" className="dark:text-white">Company Tax Advisory</label>
                                        </li>
                                        <li className="form__checkbox-item">
                                            <input type="checkbox" name="subject" id="subject" className="form__checkbox" value="Consulting" />
                                            <label htmlFor="subject" className="dark:text-white">Consulting</label>
                                        </li>
                                        <li className="form__checkbox-item">
                                            <input type="checkbox" name="subject" id="subject" className="form__checkbox" value="Individual Tax and Advisory" />
                                            <label htmlFor="subject" className="dark:text-white">Individual Tax and Advisory</label>
                                        </li>
                                        <li className="form__checkbox-item">
                                            <input type="checkbox" name="subject" id="subject" className="form__checkbox" value="Small Business Advisory" />
                                            <label htmlFor="subject" className="dark:text-white">Small Business Advisory</label>
                                        </li>
                                    </ul>
                                </div>
                                <div className="sm:col-span-2">
                                    <label htmlFor="message" className="form__label dark:text-white">Message:</label>
                                    <textarea id="message" name="message" rows="6" className="form__input placeholder-black dark:bg-[#c0bfc0]" placeholder="Leave a comment..." required></textarea>
                                </div>

                                <div className="flex justify-center">
                                    <ThemeProvider theme={theme}>
                                        <Button variant="contained" style={{ maxWidth: '250px', maxHeight: '250px', fontSize: '1.3rem' }} color="info" type="submit" value="Send">Submit</Button>
                                    </ThemeProvider>
                                </div>
                            </form>
                        </div>
                        <div className="section-grid__column">
                            <h2 className="col-title dark:text-white">Schedule an Appointment</h2>
                            <hr className="col-title__underline sm:mx-auto" id="lets-talk__col-title__underline" />
                            <div className="flex justify-center">
                                <div ref={ref} className={`animate ${inView ? 'active' : ''}`}>
                                    <img src={require("../assets/symsolutionscpa-map.png")} alt="Google map screenshot" className="section-picture" />
                                </div>
                            </div>
                            <p className="col-text dark:text-white">Are you a new prospective client and ready to schedule a time to meet with us for an <b>initial consultation</b>? If so, we can avoid the back and forth of finding a convenient time by using our scheduling tool. Simply click on the button below and see our availability.</p>
                            <p className="col-text dark:text-white">If you are an <b>existing client</b> who wishes to schedule an appointment, you can simply click on the following button and choose a time option that works best for you!</p>
                            <div className="flex justify-center mt-8">
                                <ThemeProvider theme={theme}>
                                    <Button onClick={navigateToUrl} variant="contained" style={{ maxWidth: '300px', maxHeight: '250px', fontSize: '1.3rem' }} color="info" type="submit">Schedule Appointment</Button>
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
import Posts from '../components/posts';

export default function Blog() {
    return (
        <main className="dark:bg-zinc-800">
            <section className="section">
                <div className="section-padding">
                    <h1 className="section-title dark:text-white">Blog</h1>
                    <hr className="section-title__underline dark:border-white" />
                    <Posts />
                </div>
            </section>
        </main>
    );
}
import FetchData from '../components/fetchData';
import FadeModal from '../components/fadeModal';
import { Fade } from '@mui/material';
import "../css/teamCards.css"

export default function TeamCards() {
    const cards = FetchData('https://wp.symsolutionscpa.com/wp-json/wp/v2/team_members?_embed');

    return (
        <div className="flex flex-col">
            <div className="flex flex-row flex-wrap gap-4 justify-center">
                {cards.map((member, index) => (
                    <Fade in={true} timeout={2000 + index * 950} key={index}>
                        <div className="card" key={index}>
                            <div className="card__image-container">
                                <img className="card__image sm:rounded-none sm:rounded-l-lg" src={member._embedded['wp:featuredmedia'][0].source_url} alt={member._embedded['wp:featuredmedia'][0].alt_text} />
                            </div>
                            <div className="p-5 content-center">
                                <h3 className="card__name">{member.title.rendered}</h3>
                                <h4 className="card__position">{member.acf.member_position}</h4>
                                <div className="flex flex-col items-center mt-2">
                                    <FadeModal color="info" button="About" name={member.title.rendered} description={member.acf.member_description} />
                                </div>
                            </div>
                        </div>
                    </Fade>
                ))}
            </div>
        </div>
    )
}
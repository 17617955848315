import { BriefcaseIcon, CheckCircleIcon, XCircleIcon, LightBulbIcon, LockClosedIcon, ScaleIcon, SunIcon } from '@heroicons/react/24/outline';
import {Accordion, AccordionBody, AccordionHeader, AccordionItem} from 'react-headless-accordion';
import { useInView } from 'react-intersection-observer';
import "../css/services.css"

export default function Services() {
    const [ref1, inView1] = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    const [ref2, inView2] = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    const [ref3, inView3] = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    return (
        <main className="dark:bg-zinc-800">
            <section className="section" id="virtual-services">
                <div className="section-padding lg:px-8">
                    <h1 className="section-title dark:text-white">Virtual Services</h1>
                    <hr className="section-title__underline sm:mx-auto dark:border-white" />
                    <div className="section-grid">
                        <div className="section-grid__column">
                            <div className="flex justify-center">
                                <div ref={ref1} className={`animate ${inView1 ? 'active' : ''}`}>
                                    <img src={require("../assets/WS-Virtual-Services2.jpeg")} alt="Computer" id="virtual-services-picture" className="section-picture" />
                                </div>
                            </div>
                        </div>
                        <div className="section-grid__column">
                            <p className="col-text dark:text-white">
                                Symmetry Advisory Solutions offers a comprehensive list of outsourced accounting solutions. Our experienced accountants are able to prepare taxes, offer advice and strategy on dealing with taxation, work as virtual accountants and CFOs, audit businesses to insure adequate quality control and IT systems are in place and aid in the preparation of business plans for startups. Business insight from Symmetry Advisory Solutions will keep households and businesses running legally and efficiently. Symmetry’s clients benefit from years of experience in the highest levels of accounting. Quality and experience are what set us apart. Symmetry Advisory Solutions is capable of meeting any need for financial services and is committed to exceeding expectations.
                            </p>
                            <div className="accordion-container max-w-screen-xl min-h-sceen dark:bg-[#c0bfc0]">
                                <div className="grid accordion__divide divide-y divide-black">
                                    <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }}>
                                        <AccordionItem>
                                            {({ open }) => (
                                                <>
                                                    <AccordionHeader className="flex accordion-header">
                                                        <span>Virtual Accounting</span>
                                                        <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fillRule="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                                    </AccordionHeader>
                                                    <AccordionBody>
                                                        <div className="accordion-body">
                                                            <p className="mb-4">The accounting options from Symmetry Advisory Solutions allows small business owners to reap the benefits of an experienced part-time CPA, while maintaining 24-hour real-time online access to critical financial information like cash flow, balance sheets, and payroll information. Symmetry Advisory Solutions creates a custom package of virtual accounting services for each of our clients and can offer any necessary service through our cloud-based platform: simple bookkeeping, invoicing and billing, complete financial reporting, complex financial analysis and much more.</p>
                                                            <p className="mb-4">Although we are focused on using technology to provide unparalleled value, we also recognize that firms want to hire accountants they know and trust. Symmetry’s accountants can always be contacted for more traditional conversations. Our staff has decades of traditional accounting experience, and we believe in combining the benefits of online accounting with the timeless value of relationships. It is our goal to become your trusted business advisor.</p>
                                                            <img src={require("../assets/qbo-badge.png")} alt="QuickBooks certified badge" id="QBO-badge" />
                                                        </div>
                                                    </AccordionBody>
                                                </>
                                            )}
                                        </AccordionItem>
                                    </Accordion>
                                    <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }}>
                                        <AccordionItem>
                                            {({ open }) => (
                                                <>
                                                    <AccordionHeader className="flex accordion-header">
                                                        <span>Financial Reporting</span>
                                                        <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fillRule="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                                    </AccordionHeader>
                                                    <AccordionBody>
                                                        <div className="accordion-body">
                                                            <p className="mb-4">We help you navigate the decisions of financial reporting and have CPAs with expertise preparing financial statements using the following bases: generally accepted accounting principles (GAAP), tax-basis, and AICPA's Financial Reporting Framework (FRF). The FRF for small-to-medium entities (SMEs) is a great choice for owner-managers who need financial statements that reliably report what they own, what they owe, and cash flows.</p>
                                                            <img src={require("../assets/AICPA_FRFSMElogo_Firm-blue.png")} alt="FRF for SMEs badge" id="FRF-badge" className="w-56 h-56" />
                                                        </div>
                                                    </AccordionBody>
                                                </>
                                            )}
                                        </AccordionItem>
                                    </Accordion>
                                    <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }}>
                                        <AccordionItem>
                                            {({ open }) => (
                                                <>
                                                    <AccordionHeader className="flex accordion-header">
                                                        <span>Payroll</span>
                                                        <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fillRule="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                                    </AccordionHeader>
                                                    <AccordionBody>
                                                        <div className="accordion-body">
                                                            <p className="mb-4">Symmetry has partnered with Gusto to provide payroll services to your small business.  We specialize in offering smaller companies cloud-based payroll services, like pay, benefits and tax withholding at a very affordable rate.  You'll feel like you're receiving payroll services that only much larger companies can afford. Our payroll service offers the following balanced solutions that will allow you the time to relax in a peaceful zen-like state.</p>
                                                            <ul className="mb-4">
                                                                <li>Quick and easy employee self-onboarding</li>
                                                                <li>Efficient new hire reporting</li>
                                                                <li>Beautifully designed paystubs that can be accessed anywhere and anytime... even on mobile devices</li>
                                                                <li>Accurate and on-time employer and employee payroll tax calculations and payments</li>
                                                                <li>Timely and compliant tax filings</li>
                                                                <li>Personal and friendly service with Symmetry only a call away</li>
                                                            </ul>
                                                            <img src={require("../assets/Gusto-Bronze-Partner-Badge.png")} alt="Gusto Bronze Partner badge" id="gusto-badge" className="w-80 h-60" />
                                                        </div>
                                                    </AccordionBody>
                                                </>
                                            )}
                                        </AccordionItem>
                                    </Accordion>
                                    <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }}>
                                        <AccordionItem>
                                            {({ open }) => (
                                                <>
                                                    <AccordionHeader className="flex accordion-header">
                                                        <span>Virtual CFO</span>
                                                        <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fillRule="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                                    </AccordionHeader>
                                                    <AccordionBody>
                                                        <div className="accordion-body">
                                                            <p className="mb-4">Symmetry Advisory Solutions' virtual CFO is our highest level of service and, given our decades of accounting and business consulting experience, it is often a game-changing asset to our customers.</p>
                                                            <p>Whether you are looking for an interim CFO, outsourced accounting or online bookkeeping combined with higher-level business analysis, Symmetry Advisory Solutions can design CFO solutions to meet your needs. Every level CFO services we offer comes packaged with our innovative cloud-based approach to business advisory services. Financial information can be accessed 24 hours a day and your new CFO will be readily available to answer questions and provide financial advice.</p>
                                                        </div>
                                                    </AccordionBody>
                                                </>
                                            )}
                                        </AccordionItem>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section" id="tax-advisory">
                <div className="section-padding lg:px-8">
                    <h1 className="section-title dark:text-white">Tax Advisory</h1>
                    <hr className="section-title__underline sm:mx-auto dark:border-white" />
                    <div className="section-grid">
                        <div className="section-grid__column">
                            <p className="col-text dark:text-white">
                                Experienced corporate tax accountants from Symmetry Advisory Solutions provide a full range of online tax accounting services including business tax preparation and tax filing, as well as tax advice and planning for individuals, small businesses, LLCs, and S-Corps. Our online tax analysis and tax consulting services can help your business hold on to funds and grow faster. Symmetry also evalutes IRS tax problems and works with tax attorneys to solve tax issues and reduce back taxes. Cloud-based tax solutions from Symmetry will give you and your business peace of mind and a competitive edge.
                            </p>
                            <div className="accordion-container max-w-screen-xl min-h-sceen dark:bg-[#c0bfc0]">
                                <div className="grid accordion__divide divide-y divide-black">
                                    <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }}>
                                        <AccordionItem>
                                            {({ open }) => (
                                                <>
                                                    <AccordionHeader className="flex accordion-header">
                                                        <span>Tax Return Preparation</span>
                                                        <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fillRule="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                                    </AccordionHeader>
                                                    <AccordionBody>
                                                        <div className="accordion-body">
                                                            <p className="font-bold mb-2">Company</p>
                                                            <p className="mb-4">By working with Symmetry, businesses are ensured an accurate corporate IRS filing, but without the overhead of a traditional business tax accountant. Because Symmetry works with cloud technology, our clients actually have more access to their corporate IRS documents than companies that choose a typical brick-and-mortar firm to file their corporate income tax. Symmetry's staff works closely with clients to apply our experience, ensuring accurate reporting of taxable business income and safeguarding the integrity of our client companies.</p>
                                                            <p className="font-bold mb-2">Individual</p>
                                                            <p>By working with Symmetry, clients are ensured an accurate IRS tax filing, but without the overhead of a traditional personal tax accountant. Because Symmetry works with cloud technology, our clients actually have more access to their prepared IRS documents than families or individuals who choose a typical brick-and-mortar firm to file taxes for them. Symmetry's staff works closely with clients to apply our decades of experience, ensuring accurate results in reporting taxable income to the IRS.</p>
                                                        </div>
                                                    </AccordionBody>
                                                </>
                                            )}
                                        </AccordionItem>
                                    </Accordion>
                                    <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }}>
                                        <AccordionItem>
                                            {({ open }) => (
                                                <>
                                                    <AccordionHeader className="flex accordion-header">
                                                        <span>Tax Planning and Advising</span>
                                                        <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fillRule="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                                    </AccordionHeader>
                                                    <AccordionBody>
                                                        <div className="accordion-body">
                                                            <p className="font-bold mb-2">Company</p>
                                                            <p className="mb-4">Our secure cloud-based tax consulting services help companies minimize the taxes they owe to the IRS, without paying the difference to us through exorbitant tax consulting fees. Plus, all of the business tax planning we perform for our clients can be accessed 24/7 online, on any computer from the boardroom to the bedroom. Every dollar of revenue a company produces is hard earned, and most firms cannot afford to send extra money to the IRS by overestimating their corporate tax responsibilities. Symmetry's tax consulting services ensure business income tax doesn't become an undue burden on corporate profits.</p>
                                                            <p className="font-bold mb-2">Individual</p>
                                                            <p>Our secure cloud-based tax consulting services help clients minimize their taxes, without paying the difference to us through exorbitant tax consulting fees. Plus, all of the personalized tax planning we perform for our clients can be accessed 24/7 from any computer with Internet access. Every dollar of income is hard earned, and most families and individuals cannot afford to send extra money to the IRS by overestimating their tax responsibility. Symmetry's tax consulting services ensure income tax doesn't become an undue burden on our clients' future.</p>
                                                        </div>
                                                    </AccordionBody>
                                                </>
                                            )}
                                        </AccordionItem>
                                    </Accordion>
                                    <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }}>
                                        <AccordionItem>
                                            {({ open }) => (
                                                <>
                                                    <AccordionHeader className="flex accordion-header">
                                                        <span>Addressing Tax Problems</span>
                                                        <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fillRule="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                                    </AccordionHeader>
                                                    <AccordionBody>
                                                        <div className="accordion-body">
                                                        <p className="font-bold mb-2">Company</p>
                                                            <p className="mb-4">If Symmetry reviews and researches late tax returns and finds that a company owes back taxes, we help protect your business from the IRS. Symmetry will even work with corporate tax attorneys if needed. Simply understanding the corporate tax law and practice can be difficult enough; reacting deftly to IRS tax issues requires trained experts. Retaining corporate tax accounting services from Symmetry can help protect your business and save your firm money owed on late corporate taxes.</p>
                                                            <p className="font-bold mb-2">Individual</p>
                                                            <p>If Symmetry reviews and researches unfiled tax returns and finds that an individual owes back income tax, we can help protect your income from the IRS. Symmetry will even work with income tax attorneys if needed. Simply understanding the income tax law and practice can be difficult; reacting deftly to IRS tax issues requires trained experts. Retaining personal tax accounting services from Symmetry can help protect you and save your money.</p>
                                                        </div>
                                                    </AccordionBody>
                                                </>
                                            )}
                                        </AccordionItem>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                        <div className="section-grid__column">
                            <div className="flex justify-center">
                                <div ref={ref2} className={`animate ${inView2 ? 'active' : ''}`}>
                                    <img src={require("../assets/WS-Tax-Advisory.jpg")} alt="Tablet" id="tax-advisory-picture" className="section-picture" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section" id="small-business-advisory">
                <div className="section-padding lg:px-8">
                    <h1 className="section-title dark:text-white">Small Business Advisory</h1>
                    <hr className="section-title__underline sm:mx-auto dark:border-white" />
                    <div className="section-grid">
                        <div className="section-grid__column">
                            <div className="flex justify-center">
                                <div ref={ref3} className={`animate ${inView3 ? 'active' : ''}`}>
                                    <img src={require("../assets/WS-CPA Firm Services.jpg")} alt="Clouds" id="small-business-advisory-picture" className="section-picture" />
                                </div>
                            </div>
                        </div>
                        <div className="section-grid__column">
                            <p className="col-text dark:text-white">
                                Symmetry Advisory Solutions offers accounting and business advisory solutions, as well as virtual accounting services for small-medium sized firms at all stages of the company life cycle. Symmetry helps startup companies write business plans and forecast revenue for SBA loan applications. Established companies can take advantage of Symmetry's small business consulting experience, which helps to identify and improve business processes by finding efficiencies and opportunities through in-depth analysis. Symmetry also offers internal control and IT control assessments. These risk mitigation reviews prevent misappropriation of funds and embezzlement.
                            </p>
                            <div className="accordion-container max-w-screen-xl min-h-sceen dark:bg-[#c0bfc0]">
                                <div className="grid accordion__divide divide-y divide-black">
                                    {/* <HeadlessAccordion title={services.acf.third_section_accordion.first_item.first_item_title} body={services.acf.third_section_accordion.first_item.first_item_description} />
                                            <HeadlessAccordion title={services.acf.third_section_accordion.second_item.second_item_title} body={services.acf.third_section_accordion.second_item.second_item_description} />
                                            <HeadlessAccordion title={services.acf.third_section_accordion.third_item.third_item_title} body={services.acf.third_section_accordion.third_item.third_item_description} />
                                            <HeadlessAccordion title={services.acf.third_section_accordion.fourth_item.fourth_item_title} body={services.acf.third_section_accordion.fourth_item.fourth_item_description} /> */}
                                    <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }}>
                                        <AccordionItem>
                                            {({ open }) => (
                                                <>
                                                    <AccordionHeader className="flex accordion-header">
                                                        <span>Small Business Startup</span>
                                                        <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fillRule="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                                    </AccordionHeader>
                                                    <AccordionBody>
                                                        <div className="accordion-body">
                                                            <p>Symmetry utilizes a cloud-based accounting and small business consulting platform, allowing us to offer services at a dramatic discount versus brick-and-mortar accounting firms. However, our services are incredibly personalized, and the decades of accounting experience our staff brings to the table means no business insight will go unnoticed. Because Symmetry offers online small business accounting, all of the documents associated with your small business are only a click away - a huge advantage in the 24/7 world of running a small business. Plus, Symmetry's varied service offerings means we can grow along with a small business, reducing time spent on internal financial matters and maximizing time spent generating new business. We can become your trusted business advisor.</p>
                                                        </div>
                                                    </AccordionBody>
                                                </>
                                            )}
                                        </AccordionItem>
                                    </Accordion>
                                    <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }}>
                                        <AccordionItem>
                                            {({ open }) => (
                                                <>
                                                    <AccordionHeader className="flex accordion-header">
                                                        <span>Business Process Improvement</span>
                                                        <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fillRule="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                                    </AccordionHeader>
                                                    <AccordionBody>
                                                        <div className="accordion-body">
                                                            <p>Symmetry Advisory Solutions has decades of experience in small business advisory and corporate consulting. We focus on creating detailed maps of a company's processes which offer insight far beyond identifying inefficiencies. The executives of a small business have often spent years pushing their company forward, Symmetry specializes in creating opportunities by looking back over a company's past and identifying business practices that have become redundant, outdated, or which don't support a firm's maximum growth potential. On top of that, Symmetry's cloud-based business insight platform allows us to offer these services for less than a traditional accounting firm. Your dedicated small business advisor will work directly with your firm whenever you need them, and you will be able to access their insights 24/7 via any computer with Internet access.</p>
                                                        </div>
                                                    </AccordionBody>
                                                </>
                                            )}
                                        </AccordionItem>
                                    </Accordion>
                                    <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }}>
                                        <AccordionItem>
                                            {({ open }) => (
                                                <>
                                                    <AccordionHeader className="flex accordion-header">
                                                        <span>Internal Control Assessments</span>
                                                        <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fillRule="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                                    </AccordionHeader>
                                                    <AccordionBody>
                                                        <div className="accordion-body">
                                                            <p>Few business consulting firms are able to offer services comparable to Symmetry Advisory Solutions, which has years of auditing experience in both accounting and evaluating control environments. When other financial services companies need their own internal controls reviewed, they turn to the staff of Symmetry. What sets Symmetry apart from all competition, however, is the convenience and affordability provided by their innovative and secure cloud-based business advisory platform. The progress and results of internal control assessments performed by Symmetry can be viewed 24/7 from any computer with an Internet connection. Symmetry offers peace of mind and protection from mishandled or misappropriated funds, which modern businesses cannot do without.</p>
                                                        </div>
                                                    </AccordionBody>
                                                </>
                                            )}
                                        </AccordionItem>
                                    </Accordion>
                                    <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }}>
                                        <AccordionItem>
                                            {({ open }) => (
                                                <>
                                                    <AccordionHeader className="flex accordion-header">
                                                        <span>IT Control Assessments</span>
                                                        <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fillRule="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                                    </AccordionHeader>
                                                    <AccordionBody>
                                                        <div className="accordion-body">
                                                            <p className="mb-4">Companies that undergo an information technology review or examination from Symmetry mitigate the risk of accounting fraud; And, they can be secure in the knowledge they are consciously making a decision regarding which IT controls in their financial management or accounting software are necessary versus those that are nice to have. Symmetry can provide insight into inherent vulnerabilities and the risks related to leaving the IT control environment as is.</p>
                                                            <p>Symmetry is uniquely qualified to perform IT control assessments, due to years of performing audits and IT general and application controls reviews. Our online platform allows us to perform these services without the overhead of a traditional accounting firm and offers our clients 24/7 access to our findings and loss-prevention strategies.</p>
                                                        </div>
                                                    </AccordionBody>
                                                </>
                                            )}
                                        </AccordionItem>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mx-auto mt-16 max-w-2x1 px-4 lg:max-w-4xl">
                    <h1 className="col-title dark:text-white">Micro Reviews</h1>
                    <hr className="col-title__underline sm:mx-auto" />
                </div>
                <div className="mx-auto mt-16 max-w-2x1 lg:max-w-4xl">
                    <div className="grid max-w-xl grid-cols-1 gap-y-10 gap-x-8 px-4 md:max-w-none lg:grid-cols-3 md:gap-y-16">
                        <div className="features-text relative pl-16">
                            <div className="features-list__item-title dark:text-white">
                                <div className="features-list__icon-box flex" id="microreviews">
                                    <CheckCircleIcon className="features-list__icon-outline" aria-hidden="true" />
                                </div>
                                What It Is
                            </div>
                            <ul className="features-text dark:text-white">
                                <li>A micro-size, one-to-two hour engagement to review your bookkeeping and provide you with a list of tailored ideas to help you save money.</li>
                                <li>A collaborative brainstorming session with you after we complete our analysis and compile our suggestions for you.</li>
                            </ul>
                        </div>
                        <div className="features-text relative pl-16">
                            <div className="features-list__item-title dark:text-white">
                                <div className="features-list__icon-box flex" id="microreviews">
                                    <XCircleIcon className="features-list__icon-outline" aria-hidden="true" />
                                </div>
                                What It Isn't
                            </div>
                            <ul className="features-text dark:text-white">
                                <li>An audit, review, or examination of your books.</li>
                                <li>Charging you money to learn what you already know. If our review doesn't hold any solid suggestions, we will be happy to report to you that you're doing a great job as it is and you won't be charged anything.</li>
                            </ul>
                        </div>
                        <div className="features-text relative pl-16">
                            <div className="features-list__item-title dark:text-white">
                                <div className="features-list__icon-box flex" id="microreviews">
                                    <BriefcaseIcon className="features-list__icon-outline" aria-hidden="true" />
                                </div>
                                How We Do It
                            </div>
                            <ul className="features-text dark:text-white">
                                <li>Process makes perfect! Our team follows a clear process that has yielded proven results and all our clients have benefited from implementing at least one cost-savings idea.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section" id="service-packages">
                <div className="section-padding lg:px-8">
                    <h1 className="section-title dark:text-white">Services Packages</h1>
                    <hr className="section-title__underline sm:mx-auto dark:border-white" />
                    <p className="col-text dark:text-white">
                        Symmetry offers multiple service packages to fit your needs. We offer a Secure Solution, Steadfast Solution, Salvation Solution, and Spark the Solution packages. Each package is designed to provide you with the services you need to help your business grow. For more information on these packages, please contact us at <a href="mailto:solutions@symsolutionscpa.com">solutions@symsolutionscpa.com</a>.
                    </p>
                    <div className="flex flex-col mt-8">
                        <div className="flex flex-row flex-wrap gap-5 justify-center">
                            <div className="service-package__container" id="secure-solution">
                                <div className="p-5 content-center">
                                    <div className="service-package__header">Secure Solution</div>
                                    <div className="flex flex-col items-center">
                                        <LockClosedIcon className="service-package__icon dark:bg-[#c0bfc0]" />
                                    </div>
                                    <hr className="service-package__underline sm:mx-auto" />
                                    <p className="service-package__body dark:bg-[#c0bfc0] dark:border-[#c0bfc0]">
                                        As the name suggests, this solution is designed to provide you security in knowing we will help solve tax issues, ease the pain of financial reporting, and help keep your business better organized. This is for the savvy business owner who loves doing routine bookkeeping!
                                    </p>
                                </div>
                            </div>
                            <div className="service-package__container" id="steadfast-solution">
                                <div className="p-5 content-center">
                                    <div className="service-package__header">Steadfast Solution</div>
                                    <div className="flex flex-col items-center">
                                        <ScaleIcon className="service-package__icon dark:bg-[#c0bfc0]" />
                                    </div>
                                    <hr className="service-package__underline sm:mx-auto" />
                                    <p className="service-package__body dark:bg-[#c0bfc0] dark:border-[#c0bfc0]">
                                        Do you need to spend less time in your business and more time on your business? If so, this solution might be for you. We'll be there to free up your time, provide routine bookkeeping and financial reporting, and help you with tax compliance.
                                    </p>
                                </div>
                            </div>
                            <div className="service-package__container" id="salvation-solution">
                                <div className="p-5 content-center">
                                    <div className="service-package__header">Salvation Solution</div>
                                    <div className="flex flex-col items-center">
                                        <SunIcon className="service-package__icon dark:bg-[#c0bfc0]" />
                                    </div>
                                    <hr className="service-package__underline sm:mx-auto" />
                                    <p className="service-package__body dark:bg-[#c0bfc0] dark:border-[#c0bfc0]">
                                        Looking for a total solution providing freedom from worry about accounting and tax compliance? If so, we'll provide the bookkeeping, accounting, and reporting you need on a daily, weekly, and monthly basis. We also throw in some great benefits, such as budgeting and forecasting advice, and in-depth tax and accounting advice.
                                    </p>
                                </div>
                            </div>
                            <div className="service-package__container" id="spark-the-solution">
                                <div className="p-5 content-center">
                                    <div className="service-package__header">Spark the Solution</div>
                                    <div className="flex flex-col items-center">
                                        <LightBulbIcon className="service-package__icon dark:bg-[#c0bfc0]" />
                                    </div>
                                    <hr className="service-package__underline sm:mx-auto" />
                                    <p className="service-package__body dark:bg-[#c0bfc0] dark:border-[#c0bfc0]">
                                        Need to find a secure, convenient, and fast accounting solution? Find it with QuickBooks Online! We set up and migrate data from QuickBooks desktop to QBO, sparking the move towards your Secure, Steadfast, or Salvation Solution!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Routes, Route, useNavigate } from "react-router-dom";
import { EyeIcon, GlobeAltIcon, HandThumbUpIcon } from '@heroicons/react/24/outline';
import { BanknotesIcon, BuildingOfficeIcon, BuildingStorefrontIcon, CurrencyDollarIcon, UserCircleIcon } from '@heroicons/react/20/solid';
import { Button, Fade } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Testimonials from '../components/testimonials';
import ToolsAndTips from '../components/toolsAndTips';
import Team from '../pages/team';
import Contact from '../pages/contact';
import Services from '../pages/services';
import '../css/home.css';

const theme = createTheme({
    palette: {
        secondary: {
            main: '#7a4491',
        },
        info: {
            main: '#1987c0',
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        fontFamily: [
            'Roboto',
            'sans-serif',
        ].join(','),
    },
});

export default function Home() {
    const navigate = useNavigate();
    const navigateToServices = () => {
        navigate('/services');
    };

    const navigateToTeam = () => {
        navigate('/team');
    };

    const navigateToContact = () => {
        navigate('/contact');
    };

    const [ref1, inView1] = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    const [ref2, inView2] = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    return (
        <main className="dark:bg-zinc-800">
            <section className="section" id="what-we-do">
                <div className="section-padding">
                    <h1 className="section-title dark:text-white">What We Do</h1>
                    <hr className="section-title__underline sm:mx-auto dark:border-white" />
                    <div className="section-grid">
                        <div className="section-grid__column">
                            <h2 className="col-title dark:text-white">Our Services</h2>
                            <hr className="col-title__underline sm:mx-auto" />
                            <p className="col-text dark:text-white">
                                Symmetry Advisory Solutions offers a comprehensive list of outsourced accounting solutions. Our experienced accountants are able to prepare taxes, offer advice and strategy on dealing with taxation, work as virtual accountants and CFOs, audit businesses to insure adequate quality control and IT systems are in place and aid in the preparation of business plans for startups. Business insight from Symmetry Advisory Solutions will keep households and businesses running legally and efficiently. Symmetry’s clients benefit from years of experience in the highest levels of accounting. Quality and experience are what set us apart. Symmetry Advisory Solutions is capable of meeting any need for financial services and is committed to exceeding expectations.
                            </p>
                            <ul className="services-list space-y-8 lg:max-w-none dark:text-white">
                                <Fade in={true} timeout={2000}>
                                    <li className="services-list__item-container">
                                        <div className="services-list__item dark:text-white">
                                            <CurrencyDollarIcon className="services-list__icon dark:text-white" aria-hidden="true" />
                                            Virtual Accounting:
                                        </div>{' '}
                                        <div className="inline">Flexible and easily tailored to your needs.</div>
                                    </li>
                                </Fade>
                                <Fade in={true} timeout={2500}>
                                    <li className="services-list__item-container">
                                        <div className="services-list__item dark:text-white">
                                            <BanknotesIcon className="services-list__icon dark:text-white" aria-hidden="true" />
                                            Virtual CFO:
                                        </div>{' '}
                                        <div className="inline">24/7 cloud-based accounting platform.</div>
                                    </li>
                                </Fade>
                                <Fade in={true} timeout={3000}>
                                    <li className="services-list__item-container">
                                        <div className="services-list__item dark:text-white">
                                            <UserCircleIcon className="services-list__icon dark:text-white" aria-hidden="true" />
                                            Individual Tax Advisory:
                                        </div>{' '}
                                        <div className="inline">De-stressing your taxes.</div>
                                    </li>
                                </Fade>
                                <Fade in={true} timeout={3500}>
                                    <li className="services-list__item-container">
                                        <div className="services-list__item dark:text-white">
                                            <BuildingStorefrontIcon className="services-list__icon dark:text-white" aria-hidden="true" />
                                            Small Business Advisory:
                                        </div>{' '}
                                        <div className="inline">Assisting your success.</div>
                                    </li>
                                </Fade>
                                <Fade in={true} timeout={4000}>
                                    <li className="services-list__item-container">
                                        <div className="services-list__item dark:text-white">
                                            <BuildingOfficeIcon className="services-list__icon dark:text-white" aria-hidden="true" />
                                            Company Tax Advisory:
                                        </div>{' '}
                                        <div className="inline">Managing all aspects of corporate taxes.</div>
                                    </li>
                                </Fade>
                            </ul>
                            <div className="flex justify-center mt-12">
                                <ThemeProvider theme={theme}>
                                    <Button onClick={navigateToServices} variant="contained" style={{ maxWidth: '250px', maxHeight: '250px', fontSize: '1.3rem' }} color="secondary">Our Solutions</Button>{' '}
                                    <Routes><Route path="/services" element={<Services />} /></Routes>
                                </ThemeProvider>
                            </div>
                        </div>
                        <div className="section-grid__column">
                            <h2 className="col-title dark:text-white">Pricing Model</h2>
                            <hr className="col-title__underline sm:mx-auto" />
                            <div className="flex justify-center">
                                <div ref={ref1} className={`animate ${inView1 ? 'active' : ''}`}>
                                    <img src={require("../assets/WS-Virtual Services.jpg")} alt="Woman holding particles" className="section-picture" />
                                </div>
                            </div>
                            <p className="col-text dark:text-white">
                                Symmetry utilizes a pricing model known as "value pricing". By determining upfront what services our clients' goals are and what resources are available to achieve them, we vastly decrease the chance that a project will go over budget. This model ensures strong client relationships and we design an unique package of services for each client and set a fair price to reflect those services.
                            </p>
                            <p className="col-text dark:text-white">
                                Prices are based on the assumption that you will continue to employ competent, experienced personnel. If a change in your personnel requires us to incur extra costs training your staff or otherwise performing our services, we will invoice you to cover these costs.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section" id="who-we-are">
                <div className="section-padding lg:px-8">
                    <h1 className="section-title dark:text-white">Who We Are</h1>
                    <hr className="section-title__underline sm:mx-auto dark:border-white" />
                    <div className="section-grid">
                        <div className="section-grid__column">
                            <h2 className="col-title dark:text-white">Why Symmetry?</h2>
                            <hr className="col-title__underline sm:mx-auto" />
                            <div className="flex justify-center">
                                <div ref={ref2} className={`animate ${inView2 ? 'active' : ''}`}>
                                    <img src={require("../assets/WS-About-Us.jpg")} alt="About Us" className="section-picture" />
                                </div>
                            </div>
                            <p className="col-text dark:text-white">
                                Symmetry is at the forefront of a movement towards online accounting, cloud-based financial services, and outsourced business development. Our technology creates a rare business opportunity to derive more value and receive better service while simultaneously reducing accounting costs.
                            </p>
                            <p className="col-text dark:text-white">
                                In addition to being on the cutting edge of the accounting services sector, Symmetry never compromises integrity. The need to consistently demonstrate the value of our services keeps us motivated to increase efficiency for our clients, saving them valuable time and money. Our vision is to be recognized as the most highly regarded and innovative virtual local CPA firm in the Ohio Miami Valley.
                            </p>
                        </div>
                        <div className="section-grid__column">
                            <h2 className="col-title dark:text-white">Client Testimonials</h2>
                            <hr className="col-title__underline sm:mx-auto" />
                            <p className="col-text dark:text-white">
                                Symmetry Advisory Solutions strives for excellence in all areas of accounting and business development; these testimonials from former customers confirm our expertise. Discover what past clients have to say about their experience with Symmetry.
                            </p>
                            <div>
                                <Testimonials />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mx-auto mt-12 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <div className="grid max-w-xl grid-cols-1 gap-y-10 gap-x-8 px-4 md:max-w-none lg:grid-cols-3 md:gap-y-16">
                        <div className="relative pl-16">
                            <div className="features-list__item-title dark:text-white">
                                <div className="features-list__icon-box flex">
                                    <EyeIcon className="features-list__icon-outline" aria-hidden="true" />
                                </div>
                                Vision
                            </div>
                            <p className="features-text dark:text-white" id="vision">
                                To be recognized as the most highly regarded and innovative CPA firm that never compromises integrity.                            
                            </p>
                        </div>
                        <div className="relative pl-16">
                            <div className="features-list__item-title dark:text-white">
                                <div className="features-list__icon-box flex">
                                    <GlobeAltIcon className="features-list__icon-outline" aria-hidden="true" />
                                </div>
                                Mission
                            </div>
                            <p className="features-text dark:text-white" id="mission">
                                To partner with our clients, helping them maximize their wellbeing by providing exceptional quality services.
                            </p>
                        </div>
                        <div className="relative pl-16">
                            <div className="features-list__item-title dark:text-white">
                                <div className="features-list__icon-box flex">
                                    <HandThumbUpIcon className="features-list__icon-outline" aria-hidden="true" />
                                </div>
                                Values
                            </div>
                            <ul className="features-text list-disc dark:text-white">
                                <li>Passion for Excellence</li>
                                <li>Commitment to Quality</li>
                                <li>Zeal for Integrity</li>
                                <li>Enthusiasm for Innovation</li>
                                <li>Dedication to Effective Communication</li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex justify-center gap-5 mt-8">
                        <ThemeProvider theme={theme}>
                            <Button onClick={navigateToTeam} variant="contained" style={{ maxWidth: '250px', maxHeight: '250px', fontSize: '1.3rem' }} color="secondary">Our Team</Button>{' '}
                            <Routes><Route path="/team" element={<Team />} /></Routes>
                            <Button onClick={navigateToContact} variant="contained" style={{ maxWidth: '250px', maxHeight: '250px', fontSize: '1.3rem' }} color="secondary">Contact Us</Button>{' '}
                            <Routes><Route path="/contact" element={<Contact />} /></Routes>
                        </ThemeProvider>
                    </div>
                </div>
            </section>
            <section className="section" id="tools-and-tips">
                <div className="section-padding lg:px-8">
                    <h1 className="section-title dark:text-white">Tools and Tips</h1>
                    <hr className="section-title__underline sm:mx-auto dark:border-white" />
                    <p className="col-text dark:text-white">
                        Need to determine your business' cash flow or working capital, decide whether you should pay off your mortgage in 15 years versus 30, balance your checkbook, plan for retirement, or save for college education? Try our wide variety of interactive financial problem solvers. Simply enter your criteria and you'll get your questions answered with dynamic graphs and personalized reports.
                    </p>
                    <div className="mt-5 lg:mx-60">
                        <ToolsAndTips />
                    </div>
                </div>
            </section>
        </main>
    );
}
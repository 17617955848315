import {Accordion, AccordionBody, AccordionHeader, AccordionItem} from 'react-headless-accordion';
import "../css/home.css"

export default function ToolsAndTips() {
    return (
        <div className="accordion-container max-w-screen-xl min-h-sceen dark:bg-[#c0bfc0]">
            <div className="grid accordion__divide divide-y divide-black">
                {/* <HeadlessAccordion title="Business" body={
                    <ul>
                        <li>
                            <a href="https://www.cchwebsites.com/content/calculators/BreakEven.html" target="_blank">Breakeven Analysis Calculator:</a> Find out how many and what price you must sell your product at to make a profit.
                        </li>
                    </ul>
                } />
                <HeadlessAccordion title="Mortgage" body="" />
                <HeadlessAccordion title="Credit and Debt Card Management" body="" />
                <HeadlessAccordion title="Automotive" body="" />
                <HeadlessAccordion title="Investment" body="" />
                <HeadlessAccordion title="Retirement Savings and Planning" body="" />
                <HeadlessAccordion title="Tax" body="" />
                <HeadlessAccordion title="Savings" body="" />
                <HeadlessAccordion title="Personal Finance" body="" /> */}

                <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }}>
                    <AccordionItem>
                        {({ open }) => (
                            <>
                                <AccordionHeader className="flex accordion-header">
                                    <span>Business</span>
                                    <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fillRule="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                </AccordionHeader>
                                <AccordionBody>
                                    <div className="accordion-body">
                                        <ul>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/BreakEven.html" rel="noreferrer" target="_blank" className="accordion-body__item">Breakeven Analysis Calculator:</a> Find out how many and what price you must sell your product at to make a profit.
                                            </li>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/CashFlow.html" rel="noreferrer" target="_blank" className="accordion-body__item">Cash Flow Calculator:</a> Determine the cash flow generated by your business.
                                            </li>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/Capital.html" rel="noreferrer" target="_blank" className="accordion-body__item">Working Capital Needs:</a> Determining your working capital needs for the next year.
                                            </li>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/ProfitMargin.html" rel="noreferrer" target="_blank" className="accordion-body__item">Profit Margin Calculator</a> Determine the selling price for your products to achieve a desired profit margin.
                                            </li>
                                        </ul>
                                    </div>
                                </AccordionBody>
                            </>
                        )}
                    </AccordionItem>
                </Accordion>
                <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }}>
                    <AccordionItem>
                        {({ open }) => (
                            <>
                                <AccordionHeader className="flex accordion-header">
                                    <span>Mortgage</span>
                                    <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fillRule="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                </AccordionHeader>
                                <AccordionBody>
                                    <div className="accordion-body">
                                        <ul>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/MortgageMax.html" rel="noreferrer" target="_blank" className="accordion-body__item">Maximum Mortgage Calculator:</a> Determine your maximum mortgage and how different interest rates affect how much you can borrow.
                                            </li>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/MortgageCompare.html" rel="noreferrer" target="_blank" className="accordion-body__item">Mortgage Comparison:</a> 15 Years vs. 30 Years: Compare these two mortgage terms to help you decide which term is better for you.
                                            </li>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/MortgageQualifier.html" rel="noreferrer" target="_blank" className="accordion-body__item">Mortgage Qualifier:</a> Find out if you can afford your dream home!
                                            </li>
                                        </ul>
                                    </div>
                                </AccordionBody>
                            </>
                        )}
                    </AccordionItem>
                </Accordion>
                <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }}>
                    <AccordionItem>
                        {({ open }) => (
                            <>
                                <AccordionHeader className="flex accordion-header">
                                    <span>Credit and Debt Management</span>
                                    <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fillRule="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                </AccordionHeader>
                                <AccordionBody>
                                    <div className="accordion-body">
                                        <ul>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/Consolidate.html" rel="noreferrer" target="_blank" className="accordion-body__item">Debt Consolidation Calculator:</a> Find out if you should consolidate your debt.
                                            </li>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/DebtAccel.html" rel="noreferrer" target="_blank" className="accordion-body__item">Accelerated Debt Payoff:</a> Find out how you can accelerate your debt payoff.
                                            </li>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/DebtPayoff2.html" rel="noreferrer" target="_blank" className="accordion-body__item">Credit Card Minimum Payment Calculator:</a> Determine how long it will take you to payoff your credit cards when only making minimum payments.
                                            </li>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/CardOptimizer.html" rel="noreferrer" target="_blank" className="accordion-body__item">Credit Card Optimizer:</a> Determine the best distribution of your credit card debt.
                                            </li>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/DebtRolldown.html" rel="noreferrer" target="_blank" className="accordion-body__item">Roll-Down Your Credit Debt:</a> The credit card roll-down applies two simple principles to paying off your credit card debt.
                                            </li>
                                        </ul>
                                    </div>
                                </AccordionBody>
                            </>
                        )}
                    </AccordionItem>
                </Accordion>
                <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }}>
                    <AccordionItem>
                        {({ open }) => (
                            <>
                                <AccordionHeader className="flex accordion-header">
                                    <span>Automotive</span>
                                    <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fillRule="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                </AccordionHeader>
                                <AccordionBody>
                                    <div className="accordion-body">
                                        <ul>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/AutoLoan.html" rel="noreferrer" target="_blank" className="accordion-body__item">Car Loan Calculator:</a> Determine your car loan payment based on your purchase price or find out how much you can buy based on your monthly payment.
                                            </li>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/ShouldIRefiAuto.html" rel="noreferrer" target="_blank" className="accordion-body__item">Auto Refinance Interest Savings:</a> See how much interest you can save by refinancing your auto loan.
                                            </li>
                                        </ul>
                                    </div>
                                </AccordionBody>
                            </>
                        )}
                    </AccordionItem>
                </Accordion>
                <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }}>
                    <AccordionItem>
                        {({ open }) => (
                            <>
                                <AccordionHeader className="flex accordion-header">
                                    <span>Investment</span>
                                    <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fillRule="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                </AccordionHeader>
                                <AccordionBody>
                                    <div className="accordion-body">
                                        <ul>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/InvestmentVariables.html" rel="noreferrer" target="_blank" className="accordion-body__item">Investment Goal Calculator:</a> See if your investment plan is on track to meet your investment goals and receive suggestions on how to change it if you are falling short.
                                            </li>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/InvestmentProperty.html" rel="noreferrer" target="_blank" className="accordion-body__item">Investment Property Calculator:</a> Examine the potential return you might receive from an investment property.
                                            </li>
                                        </ul>
                                    </div>
                                </AccordionBody>
                            </>
                        )}
                    </AccordionItem>
                </Accordion>
                <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }}>
                    <AccordionItem>
                        {({ open }) => (
                            <>
                                <AccordionHeader className="flex accordion-header">
                                    <span>Retirement Savings and Planning</span>
                                    <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fillRule="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                </AccordionHeader>
                                <AccordionBody>
                                    <div className="accordion-body">
                                        <ul>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/Retire401k.html" rel="noreferrer" target="_blank" className="accordion-body__item">401(k) Savings Calculator:</a> See why this is a retirement savings plan you cannot afford to pass up.
                                            </li>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/NoSocial.html" rel="noreferrer" target="_blank" className="accordion-body__item">How important is Social Security?:</a> Determine how losing this important retirement asset could affect you.
                                            </li>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/RetirementDistribution.html" rel="noreferrer" target="_blank" className="accordion-body__item">How long will my retirement savings last?:</a> See how long your retirement savings will last based on your retirement savings and your inflation adjusted withdrawals.
                                            </li>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/RetirementNestegg.html" rel="noreferrer" target="_blank" className="accordion-body__item">Retirement "Nest Egg" Calculator:</a> Determine what size your retirement "nest egg" should be.
                                            </li>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/RothvsTraditional401k.html" rel="noreferrer" target="_blank" className="accordion-body__item">Roth 401(k) vs. Traditional 401(k) Calculator:</a> Determine if a Roth 401(k) or Traditional 401(k) might be best for you.
                                            </li>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/SocialSecurity.html" rel="noreferrer" target="_blank" className="accordion-body__item">Social Security Benefits Calculator:</a> Estimate your Social Security benefits.
                                            </li>
                                        </ul>
                                    </div>
                                </AccordionBody>
                            </>
                        )}
                    </AccordionItem>
                </Accordion>
                <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }}>
                    <AccordionItem>
                        {({ open }) => (
                            <>
                                <AccordionHeader className="flex accordion-header">
                                    <span>Tax</span>
                                    <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fillRule="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                </AccordionHeader>
                                <AccordionBody>
                                    <div className="accordion-body">
                                        <ul>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/Tax1040.html" rel="noreferrer" target="_blank" className="accordion-body__item">1040 Tax Calculator:</a> Enter your filing status, income, deductions and credits and we will estimate your total tax. Based on your projected withholdings for the year, we can also estimate your tax refund or amount you may owe the IRS next April.
                                            </li>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/EarnedIncomeCredit.html" rel="noreferrer" target="_blank" className="accordion-body__item">Earned Income Credit Calculator:</a> Determine if your qualify for the Earned Income Credit and if so, how much it might be worth.
                                            </li>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/EstatePlan.html" rel="noreferrer" target="_blank" className="accordion-body__item">Estate Tax Planning:</a> Estimate your estate tax liability.
                                            </li>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/Payroll125.html" rel="noreferrer" target="_blank" className="accordion-body__item">Flexible Spending Account (FSA) Calculator:</a> See how participating in Flexible Spending Account (FSA) contributions can help you pay less tax, and increase your net take home pay.
                                            </li>
                                        </ul>
                                    </div>
                                </AccordionBody>
                            </>
                        )}
                    </AccordionItem>
                </Accordion>
                <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }}>
                    <AccordionItem>
                        {({ open }) => (
                            <>
                                <AccordionHeader className="flex accordion-header">
                                    <span>Savings</span>
                                    <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fillRule="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                </AccordionHeader>
                                <AccordionBody>
                                    <div className="accordion-body">
                                        <ul>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/SpendLess.html" rel="noreferrer" target="_blank" className="accordion-body__item">Benefits of Spending Less:</a> See just how much your budget reductions may be worth.
                                            </li>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/CollegeSavings.html" rel="noreferrer" target="_blank" className="accordion-body__item">College Savings Calculator:</a> Develop or fine tune your education savings plan.
                                            </li>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/Millionaire.html" rel="noreferrer" target="_blank" className="accordion-body__item">Cool Million Calculator:</a> Find out when your savings plan may make you a millionaire!
                                            </li>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/ViceSaver.html" rel="noreferrer" target="_blank" className="accordion-body__item">Vice Savings Calculator:</a> See how simple changes in your spending habits for common vices such as smoking, drinking, or even eating out can really add up.
                                            </li>
                                        </ul>
                                    </div>
                                </AccordionBody>
                            </>
                        )}
                    </AccordionItem>
                </Accordion>
                <Accordion transition={{ duration: "300ms", timingFunction: "cubic-bezier(0, 0, 0.2, 1)" }}>
                    <AccordionItem>
                        {({ open }) => (
                            <>
                                <AccordionHeader className="flex accordion-header">
                                    <span>Personal Finance</span>
                                    <svg className={`w-6 h-6 ${!open ? '' : 'rotate-90'}`} fillRule="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                                </AccordionHeader>
                                <AccordionBody>
                                    <div className="accordion-body">
                                        <ul>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/HomeBudget.html" rel="noreferrer" target="_blank" className="accordion-body__item">Home Budget Analysis Calculator:</a> Analyze your budget, see where your money goes and find out where you can improve!
                                            </li>
                                            <li>
                                                <a href="https://www.cchwebsites.com/content/calculators/LifeTime.html" rel="noreferrer" target="_blank" className="accordion-body__item">Life Expectancy Calculator:</a> Get an idea of your life expectancy based on your current age, smoking habits, gender, and several other important lifestyle choices.
                                            </li>
                                        </ul>
                                    </div>
                                </AccordionBody>
                            </>
                        )}
                    </AccordionItem>
                </Accordion>
            </div>
        </div>
    )
}